import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import './AppFooter.css'; // Ensure you create this CSS file for styling

function AppFooter() {
  const [showTopBtn, setShowTopBtn] = useState(false);
  const [email, setEmail] = useState('');

  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.scrollY > 400) {
        setShowTopBtn(true);
      } else {
        setShowTopBtn(false);
      }
    });
  }, []);

  function goTop() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }

  function handleSubmit(e) {
    e.preventDefault();
    console.log('Email submitted:', email);
  }

  return (
    <Container fluid className="footer">
      <hr className="footer-separator" />
      <div className="footer-row">
        <div className="footer-section">
          <h3>Services</h3>
          <p>Custom manpower and equipment solutions in Saudi Arabia.</p>
        </div>
        <div className="footer-section">
          <h3>Call Us</h3>
          <p>+966 54 955 6517</p>
          <p>+966 59 080 7169</p>
          <p>contact@asmcservices.com</p>
        </div>
        <div className="footer-section">
          <h3>Support</h3>
          <form onSubmit={handleSubmit}>
            <label htmlFor="email">Enter your email address</label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={e => setEmail(e.target.value)}
              placeholder="EMAIL"
              required
            />
            <button type="submit">Submit your request now</button>
          </form>
        </div>
      </div>
      <div className="socials">
        <ul>
          <li>
            <a href="https://www.facebook.com">
              <i className="fab fa-facebook-f"></i>
            </a>
          </li>
          <li>
            <a href="https://www.twitter.com">
              <i className="fab fa-twitter"></i>
            </a>
          </li>
          <li>
            <a href="https://www.linkedin.com">
              <i className="fab fa-linkedin-in"></i>
            </a>
          </li>
        </ul>
      </div>
      <div className="copyright">
        &copy; 2022 Corporate. All Right Reserved.
      </div>
      {showTopBtn && (
        <div className="go-top" onClick={goTop}>
          ↑
        </div>
      )}
    </Container>
  );
}

export default AppFooter;
