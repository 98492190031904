import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './AppServices.css'; // Add custom styles for your services page

const servicesData = [
  {
    id: 1,
    icon: 'fas fa-clone',
    title: 'Engineers',
    description:
      'Civil Engineers, Mechanical Engineers, Electrical Engineers, Project Managers, Site Supervisors, Quality Control Inspectors, Safety Officers',
  },
  {
    id: 2,
    icon: 'fas fa-snowflake',
    title: 'Technical and Skilled Labor',
    description:
      'Welders (MIG, TIG, Arc), Electricians, Plumbers, HVAC Technicians, Carpenters, Scaffolders, Equipment Operators (Cranes, Excavators, etc.), Heavy Vehicle Drivers',
  },
  {
    id: 3,
    icon: 'fas fa-plug',
    title: 'Support Staff',
    description:
      'General Laborers, Foremen, Technicians (Mechanical, Electrical, etc.), Logistics Coordinators, Administrative Staff',
  },
  {
    id: 4,
    icon: 'fas fa-desktop',
    title: 'Equipment Supply Services',
    description:
      'We provide essential equipment tailored for various industries and projects across Saudi Arabia.',
  },
  {
    id: 5,
    icon: 'fas fa-trophy',
    title: 'Customized Staffing Solutions',
    description:
      'Our staffing solutions are flexible and designed to fit the unique requirements of your business.',
  },
  {
    id: 6,
    icon: 'fas fa-life-ring',
    title: 'Quality Assurance',
    description:
      'We ensure quality and reliability in every manpower supply service we provide to our clients.',
  },
];

function AppServices() {
  return (
    <section id="services" className="services-section">
      <Container>
        <div className="title-holder text-center">
          <h2>Our Services</h2>
          <div className="subtitle">Services We Provide</div>
        </div>
        <Row>
          {servicesData.map(service => (
            <Col md={4} sm={6} key={service.id} className="service-card">
              <div className="card">
                <div className="icon">
                  <i className={service.icon}></i>
                </div>
                <h3>{service.title}</h3>
                <p>{service.description}</p>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
}

export default AppServices;
