import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

function AppContact() {
  return (
    <section id="contact" className="block contact-block">
      <Container fluid>
        <div className="title-holder">
          <h2>Contact us</h2>
          <div className="subtitle">get connected with us</div>
        </div>
        <Form className="contact-form">
          <Row>
            <Col sm={4}>
              <Form.Control
                type="text"
                placeholder="Enter your full name"
                required
              />
            </Col>
            <Col sm={4}>
              <Form.Control
                type="email"
                placeholder="Enter your email address"
                required
              />
            </Col>
            <Col sm={4}>
              <Form.Control
                type="tel"
                placeholder="Enter your contact number"
                required
              />
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <Form.Control
                as="textarea"
                placeholder="Enter your contact message"
                required
              />
            </Col>
          </Row>
          <div className="btn-holder">
            <Button type="submit">Submit</Button>
          </div>
        </Form>
      </Container>
      <div className="google-map">
        <iframe
          title="map"
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d57684.68364940641!2d49.594886!3d25.361504!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e3796e30ed2df5b%3A0x2340faa9012370cd!2sAl%20Hofuf%20Saudi%20Arabia!5e0!3m2!1sen!2sus!4v1731418876091!5m2!1sen!2sus"
        ></iframe>
      </div>
      <Container fluid>
        <div className="contact-info">
          <ul>
            <li>
              <i className="fas fa-envelope"></i>
              contact@asmc.com.sa
            </li>
            <li>
              <i className="fas fa-phone"></i>
               Mohammad Shahid
              Ullah WhatsApp & Phone: +966 54 9556517 ||
              Mohammad
              Tajul Islam WhatsApp & Phone: +966 590807169 
            </li>
            <li>
              <i className="fas fa-map-marker-alt"></i>
              P.O. Box: 45055
              Al-Ahsa 31982 Al-Ahsa - Saudi Arabia
            </li>
          </ul>
        </div>
      </Container>
    </section>
  );
}

export default AppContact;
