import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Pagination from 'react-bootstrap/Pagination';

const worksData = [
  {
    id: 1,
    link: 'https://www.google.com',
    image: require('../assets/images/1.png'),
    title: 'Name',
    subtitle: '',
  },
  {
    id: 2,
    link: 'https://www.google.com',
    image: require('../assets/images/2.png'),
    title: 'Who are we',
    subtitle: '',
  },
  {
    id: 3,
    link: 'https://www.google.com',
    image: require('../assets/images/3.png'),
    title: 'our scope',
    subtitle: '',
  },
  {
    id: 4,
    link: 'https://www.google.com',
    image: require('../assets/images/4.png'),
    title: 'Images',
    subtitle: '',
  },
  {
    id: 5,
    link: 'https://www.google.com',
    image: require('../assets/images/5.png'),
    title: 'The Difference',
    subtitle: '',
  },
  {
    id: 6,
    link: 'https://www.google.com',
    image: require('../assets/images/6.png'),
    title: 'Manpower Hire',
    subtitle: '',
  },
  {
    id: 7,
    link: 'https://www.google.com',
    image: require('../assets/images/7.png'),
    title: 'Images',
    subtitle: '',
  },
  {
    id: 8,
    link: 'https://www.google.com',
    image: require('../assets/images/8.png'),
    title: 'Images',
    subtitle: '',
  },
  {
    id: 9,
    link: 'https://www.google.com',
    image: require('../assets/images/9.png'),
    title: 'Images',
    subtitle: '',
  },
];

let active = 1;
let items = [];
for (let number = 1; number <= 4; number++) {
  items.push(
    <Pagination.Item key={number} active={number === active}>
      {number}
    </Pagination.Item>
  );
}

function AppWorks() {
  return (
    <section id="works" className="block works-block">
      <Container fluid>
        <div className="title-holder">
          <h2>Our works</h2>
          <div className="subtitle">our awesome works</div>
        </div>
        <Row className="portfoliolist">
          {worksData.map(works => {
            return (
              <Col sm={4} key={works.id}>
                <div className="portfolio-wrapper">
                  <a href={works.link}>
                    <Image src={works.image} />
                    <div className="label text-center">
                      <h3>{works.title}</h3>
                      <p>{works.subtitle}</p>
                    </div>
                  </a>
                </div>
              </Col>
            );
          })}
        </Row>
        <Pagination>{items}</Pagination>
      </Container>
    </section>
  );
}

export default AppWorks;
