import Carousel from 'react-bootstrap/Carousel';

var heroData = [
  {
    id: 1,
    image: require('../assets/images/arc.png'),
    title: 'Adel Saad Al-Matar Contracting Establishment',
    description:
      'We deliver tailored manpower and equipment services, ensuring high-quality solutions for your business.',
    link: 'https://www.facebook.com/profile.php?id=61565346779867',
  },
  {
    id: 2,
    image: require('../assets/images/Engineer.jpg'),
    title: 'Equipment Supply & Rental Services',
    description:
      'Providing top-tier equipment to meet your industrial and construction needs. Efficiency and quality are at our core.',
    link: 'https://www.facebook.com/profile.php?id=61565346779867',
  },
  {
    id: 3,
    image: require('../assets/images/hats.png'),
    title: 'Expert Manpower Solutions',
    description:
      'We offer a skilled workforce for various sectors, including construction, manufacturing, and more.',
    link: 'https://www.facebook.com/profile.php?id=61565346779867',
  },
  {
    id: 4,
    image: require('../assets/images/Engineer.jpg'),
    title: 'Why Choose Us?',
    description:
      'Over two decades of experience, trusted by leading businesses in the region. Your success is our mission.',
    link: 'https://www.facebook.com/profile.php?id=61565346779867',
  },
];

function AppHero() {
  return (
    <>
      <section id="home" className="hero-block">
        <Carousel>
          {heroData.map(hero => {
            return (
              <Carousel.Item key={hero.id}>
                <img
                  className="d-block w-100"
                  src={hero.image}
                  alt={'slide ' + hero.id}
                />
                <Carousel.Caption>
                  <h1>{hero.title}</h1>
                  <p>{hero.description}</p>
                  <a className="btn btn-primary" href={hero.link}>
                    Learn More <i className="fas fa-chevron-right"></i>
                  </a>
                </Carousel.Caption>
              </Carousel.Item>
            );
          })}
        </Carousel>
      </section>

      {/* Separator with Additional Space */}
      <hr className="section-separator" />

      {/* New About Us Section with Image and Text */}
      <section id="about" className="about-us-block">
        <div className="container about-container">
          <div className="row align-items-center">
            <div className="col-md-4">
              {/* Left Side Image */}
              <img
                src={require('../assets/images/Engineer.jpg')}
                alt="About Us"
                className="about-us-image"
              />
            </div>
            <div className="col-md-8">
              {/* Right Side Text */}
              <h1 className="section-title">
                Adel Saad Al-Matar Contracting Establishment
              </h1>
              <p>
                Adel Saad Al-Matar Contracting
                Establishment is a leader in manpower and equipment supply
                services, dedicated to helping businesses achieve their
                operational goals with maximum efficiency. We specialize in
                providing tailored solutions that meet the specific needs of our
                clients, ensuring high-quality results across various
                industries, including construction, engineering, and
                manufacturing.
              </p>
              <p>
                Our core services include manpower supply, equipment rental, and
                general contracting. With a focus on customer satisfaction and
                long-term partnerships
              </p>
              <p>
                We are proud to have a diverse team of experts who bring their
                experience and skill to every project we undertake. Whether you
                need manpower for a specific task or equipment to get the job
                done, you can rely on us to provide top-notch services that help
                your business succeed.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default AppHero;
