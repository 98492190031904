import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import ProgressBar from 'react-bootstrap/ProgressBar';
import AppBlog from './AppBlog'; // Import AppBlog here

import img1 from '../assets/images/Your paragraph text (1).png';

function AppAbout() {
  const Manpower = 70;
  const EquipmentRental = 20;

  return (
    <section id="about" className="block about-block">
      <Container fluid>
        <div className="title-holder">
          <h2>About Us</h2>
          <div className="subtitle">learn more about us</div>
        </div>
        <Row>
          <Col sm={6}>
            <Image src={img1} />
          </Col>
          <Col sm={6}>
            <h1>Your Trusted Manpower Supply Partner</h1>
            <p>
              Adel Saad Al-Matar Contracting Est. is a premier contracting
              establishment, excelling in manpower supply services across
              diverse industries. Specialising in the hiring and rental of
              skilled professionals, the company also offers equipment rental,
              construction, and material supply services.
            </p>
            <p>
              Our commitment is to deliver reliable manpower supply services and
              high-quality equipment, ensuring that your projects are supported
              by skilled professionals and the best tools available in the
              industry.
            </p>
            <div className="progress-block">
              <h4>Manpower Supply</h4>
              <ProgressBar now={Manpower} label={`${Manpower}%`} />
            </div>
            <div className="progress-block">
              <h4>Equipment Rental</h4>
              <ProgressBar
                now={EquipmentRental}
                label={`${EquipmentRental}%`}
              />
            </div>
          </Col>
        </Row>
      </Container>
      <AppBlog /> {/* Include AppBlog here */}
    </section>
  );
}

export default AppAbout;
