import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import AppHeader from './components/header';
import AppHero from './components/hero';
import AppAbout from './components/about'; // This now includes AppBlog
import AppServices from './components/services';
import AppWorks from './components/AppWorks';
import AppTeams from './components/AppTeams';

import AppPricing from './components/pricing';
import AppContact from './components/AppContact';
import AppFooter from './components/AppFooter';

function App() {
  return (
    <Router>
      <div className="App">
        <header id="header">
          <AppHeader />
        </header>
        <main>
          <Routes>
            <Route path="/about" element={<AppAbout />} />
            <Route path="/services" element={<AppServices />} />
            <Route path="/works" element={<AppWorks />} />
            <Route path="/teams" element={<AppTeams />} />
            <Route path="/pricing" element={<AppPricing />} />
            <Route path="/contact" element={<AppContact />} />
            <Route path="/" element={<AppHero />} />
          </Routes>
        </main>
        <footer id="footer">
          <AppFooter />
        </footer>
      </div>
    </Router>
  );
}

export default App;
